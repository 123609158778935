import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import AvatarMan from 'assets/images/index/avatar-man.png';
import AvatarWoman from 'assets/images/index/avatar-woman.png';
import BaptisteTrin from 'assets/images/student/baptiste-trin.png';
import GenevievePonsonnet from 'assets/images/index/genevieve-ponsonnet.jpeg';
import GuillaumeGrzych from 'assets/images/index/guillaume-grzych.png';
import PaulNibaudeau from 'assets/images/student/paul-nibaudeau.jpeg';

import styles from './Testimony.module.scss';

const cx = classNames.bind(styles);

const IMAGES = {
  'genevieve-ponsonnet': GenevievePonsonnet,
  'guillaume-grzych': GuillaumeGrzych,
  'baptiste-trin': BaptisteTrin,
  'paul-nibaudeau': PaulNibaudeau,
  'avatar-man': AvatarMan,
  'avatar-woman': AvatarWoman,
};

const Testimony = ({ content, isSmallCard }) => (
  <div className={cx(isSmallCard ? 'small-container' : 'container')}>
    <p className={cx(isSmallCard ? 'small-content' : 'content')}>
      {content.content}
    </p>
    <div className={cx('author')}>
      <img alt="" className={cx('picture')} src={IMAGES[content.image]} />
      <div className={cx('author-content')}>
        <div className={cx('header')}>
          <p className={cx('name')}>{content.author}</p>
          <p className={cx('level')}>{content.level}</p>
        </div>
        <p className={cx('biography')}>{content.biography}</p>
      </div>
    </div>
  </div>
);

Testimony.propTypes = {
  content: PropTypes.object.isRequired,
  isSmallCard: PropTypes.bool,
};

Testimony.defaultProps = { isSmallCard: false };

export default Testimony;
