import classNames from 'classnames/bind';
import React from 'react';
import PropType from 'prop-types';

import LandingStudent from 'assets/images/student/landing-student.svg';
import FeatureImage from 'assets/images/student/feature-image.png';
import FlashcardImage from 'assets/images/student/flashcard.jpg';
import Tuto1 from 'assets/images/student/tuto1.png';
import Tuto2 from 'assets/images/student/tuto2.jpg';
import Tuto3 from 'assets/images/student/tuto3.png';

import Layout from 'components/Layout';
import Testimony from 'components/Shared/Testimony';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';

import styles from './Student.module.scss';

const cx = classNames.bind(styles);

const StudentPage = ({ pageContext: { lang, layout, content, urls } }) => (
  <Layout lang={lang} t={layout} urls={urls}>
    <main className={cx('main')}>
      <div className={cx('bubbles')}>
        {/* Bloc 1 */}
        <section className={cx('student-progress')}>
          <div className={cx('text-bloc')}>
            <div className={cx('student-progress-title')}>
              {content.bloc1Title}
            </div>
            <p className={cx('student-progress-paragraph')}>
              {content.bloc1Text}
            </p>
            <p className={cx('student-progress-paragraph')}>
              {content.bloc1Text2}
            </p>
            <div className={cx('actions')}>
              <Button
                as="a"
                className={cx('cta-button')}
                href={content.CTA.link}
                intent="primary"
                size="large"
              >
                {content.CTA.label}
              </Button>
            </div>
          </div>
          <img alt="" className={cx('landing-student')} src={LandingStudent} />
        </section>
        {/* Bloc 2 */}
        <section className={cx('cards-section')}>
          <div className={cx('cards-title')}>{content.bloc2Title}</div>
          <div className={cx('cards')}>
            <div className={cx('card')}>
              <div className={cx('card-icon')}>
                <Icon className={cx('icon')} name="dumbbell" />
              </div>
              <h3 className={cx('card-title')}>
                {content.bloc2Part1Title}
                <strong>{content.bloc2Part1TitleStrong}</strong>
              </h3>
              <p className={cx('paragraph')}>{content.bloc2Part1Text}</p>
            </div>
            <div className={cx('card')}>
              <div className={cx('card-icon')}>
                <Icon className={cx('icon')} name="head-side-brain" />
              </div>
              <h3 className={cx('card-title')}>
                <strong>{content.bloc2Part2TitleStrong}</strong>
                {content.bloc2Part2Title}
              </h3>
              <p className={cx('paragraph')}>{content.bloc2Part2Text}</p>
            </div>
            <div className={cx('card')}>
              <div className={cx('card-icon')}>
                <Icon className={cx('icon')} name="users" />
              </div>
              <h3 className={cx('card-title')}>
                {content.bloc2Part3Title}
                <strong>{content.bloc2Part3TitleStrong}</strong>
              </h3>
              <p className={cx('paragraph')}>{content.bloc2Part3Text}</p>
            </div>
          </div>
        </section>
        {/* Bloc 3 */}
        <section className={cx('flashcard')}>
          <img alt="" className={cx('flashcard-image')} src={FlashcardImage} />
          <div className={cx('flashcard-text-container')}>
            <h2 className={cx('flashcard-title')}>
              {content.flashcardSection.title}
            </h2>
            <p className={cx('flashcard-subtitle')}>
              <strong>{content.flashcardSection.subtitlePart1Strong}</strong>
              {content.flashcardSection.subtitlePart1}
              <strong>{content.flashcardSection.subtitlePart2Strong}</strong>
              {content.flashcardSection.subtitlePart2}
              <strong>{content.flashcardSection.subtitlePart3Strong}</strong>
              {content.flashcardSection.subtitlePart3}
            </p>
            <Button
              as="a"
              className={cx('flashcard-cta')}
              href={content.flashcardSection.link}
              target="_blank"
              intent="primary"
              size="large"
            >
              {content.flashcardSection.cta}
            </Button>
          </div>
        </section>
        {/* Bloc 4 */}
        <section className={cx('testimony')}>
          <div className={cx('secondary-testimonies')}>
            <Testimony content={content.testimony2} />
            <Testimony content={content.testimony3} />
          </div>
        </section>
        {/* Bloc 5 */}
        <section className={cx('feature')}>
          <img alt="" className={cx('feature-image-3')} src={FeatureImage} />
          <div className={cx('text-bloc')}>
            <h2 className={cx('feature-title')}>{content.bloc5Title}</h2>
            <p className={cx('paragraph')}>{content.bloc5Text}</p>
          </div>
        </section>
        {/* Bloc 6 */}
        <section>
          <div className={cx('long-cards')}>
            <div className={cx('long-card')}>
              <div className={cx('long-card-icon-wrapper')}>
                <Icon className={cx('long-card-icon')} name="laugh-wink" />
              </div>
              <h3 className={cx('long-card-title')}>
                {content.bloc6Part1Title}
              </h3>
              <p className={cx('long-card-paragraph')}>
                {content.bloc6Part1Text1}
              </p>
              <p className={cx('long-card-paragraph')}>
                {content.bloc6Part1Text2}
              </p>
              <p className={cx('long-card-paragraph')}>
                {content.bloc6Part1Text3}
              </p>
              <div className={cx('long-card-image-wrapper')}>
                <img
                  alt="tutoriel-1"
                  className={cx('long-card-image')}
                  src={Tuto1}
                />
              </div>
            </div>
            <div className={cx('long-card')}>
              <div className={cx('long-card-icon-wrapper')}>
                <Icon className={cx('long-card-icon')} name="brain" />
              </div>
              <h3 className={cx('long-card-title')}>
                {content.bloc6Part2Title}
              </h3>
              <p className={cx('long-card-paragraph')}>
                {content.bloc6Part2Text1}
              </p>
              <p className={cx('long-card-paragraph')}>
                {content.bloc6Part2Text2}
              </p>
              <div className={cx('long-card-image-wrapper')}>
                <img
                  alt="tutoriel-2"
                  className={cx('long-card-image', 'space-top')}
                  src={Tuto2}
                />
              </div>
            </div>
            <div className={cx('long-card')}>
              <div className={cx('long-card-icon-wrapper')}>
                <Icon className={cx('long-card-icon')} name="rocket" />
              </div>
              <h3 className={cx('long-card-title')}>
                {content.bloc6Part3Title}
              </h3>
              <p className={cx('long-card-paragraph')}>
                {content.bloc6Part3Text}
              </p>
              <div className={cx('long-card-image-wrapper')}>
                <img
                  alt="tutoriel-3"
                  className={cx('long-card-image')}
                  src={Tuto3}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
    {/* Bloc 7 */}
    <section className={cx('community')}>
      <h2 className={cx('community-title')}>{content.bloc7text}</h2>
      <p>
        <Button
          as="a"
          className={cx('community-action')}
          href={content.CTA.link}
          intent="primary"
          size="large"
        >
          {content.CTA.label}
        </Button>
      </p>
    </section>
  </Layout>
);

StudentPage.propTypes = {
  pageContext: PropType.object.isRequired,
};

export default StudentPage;
